import { ISOCountry } from '@agentsonly/models';

import type { Prefix, Range } from './bank';

type BankCode = {
  code: string;
  title: string;
};

export const PHILIPPINES_BANK_CODES = [
  'AMA',
  'ANZ',
  'AB',
  'ASB',
  'AUB',
  'BDO',
  'ONB',
  'BPI',
  'BPIF',
  'BANGK',
  'BCH',
  'BONE',
  'BOC',
  'BOT',
  'CRBR',
  'CTB',
  'CBS',
  'CB',
  'CITIS',
  'CITI',
  'CITE',
  'CITY',
  'DB',
  'DBP',
  'EWB',
  'EQSB',
  'FEB',
  'PILSB',
  'FAB',
  'FCB',
  'FMB',
  'GCASH',
  'GSB',
  'HSBCS',
  'HSBC',
  'IBANK',
  'ISLAM',
  'LBP',
  'LDB',
  'MAL',
  'MAY',
  'MBTC',
  'PBANK',
  'PBCOM',
  'PBB',
  'PNB',
  'PSB',
  'PTB',
  'PVB',
  'PLB',
  'POB',
  'PRB',
  'RCBC',
  'RSB',
  'SB',
  'SCB',
  'SEA',
  'STRBK',
  'TYB',
  'UCPBS',
  'UB',
  'UCPB',
  'UOB',
  'USB',
] as const;

export const PHILIPPINES_BANK_INFO_BY_BANK_CODES: Record<
  string,
  { title: string; range?: Range; prefix?: Prefix }
> = {
  AMA: { title: 'AMA Bank', range: [16] },
  ANZ: { title: 'ANZ Bank', range: [11] },
  AB: { title: 'Allied Bank', range: [10] },
  ASB: { title: 'Allied Savings Bank', range: [10] },
  AUB: { title: 'Asia United Bank', range: [12] },
  BDO: {
    title: 'BDO (Banco De Oro) Unibank',
    range: [10, 12],
  },
  BPI: {
    title: 'BPI (Bank of the Philippine Islands)',
    range: [10],
  },
  BPIF: { title: 'BPI Family Savings Bank', range: [10] },
  BANGK: { title: 'Bangkok Bank', range: [13] },
  BCH: { title: 'Bank Of China' },
  BONE: {
    title: 'Bank One Savings and Trust Corp',
    range: [10],
  },
  BOC: { title: 'Bank of Commerce', range: [12] },
  BOT: { title: 'Bank of Tokyo', range: [13] },
  // 'CIMB': { title: 'CIMB BANK PHILIPPINES INC.' , range: [	] },
  CRBR: {
    title: 'COMMUNITY RURAL BANK OF ROMBLON,INC',
  },
  CTB: { title: 'CTBC' },
  CBS: { title: 'China Bank Savings', range: [12] },
  CB: {
    title: 'China Banking Corporation',
    range: [10, 12],
  },
  CITIS: { title: 'CitiBank Savings', range: [10, 16] },
  CITI: { title: 'Citibank', range: [10, 16] },
  CITE: { title: 'City Estate Savings Bank', range: [12] },
  CITY: { title: 'City Savings Bank', range: [10] },
  DB: { title: 'Deutsche Bank', range: [10] },
  DBP: {
    title: 'Development Bank of the Philippines',
    range: [10],
  },
  EWB: { title: 'East West Bank', range: [12] },
  EQSB: { title: 'Equicom Savings Bank', range: [11] },
  FEB: { title: 'Far Eastern Bank' },
  PILSB: {
    title: 'Filipino Savers Bank (A Rural Bank)',
    range: [10],
  },
  FAB: { title: 'First Allied Bank', range: [10] },
  FCB: { title: 'First Consolidated Bank', range: [14] },
  FMB: { title: 'First Macro Bank', range: [8] },
  GCASH: { title: 'Gcash', range: [12], prefix: 63 },
  GSB: { title: 'Guagua Savers Bank', range: [8] },
  HSBCS: { title: 'HSBC Savings', range: [12] },
  HSBC: { title: 'Hongkong & Shanghai Bank', range: [12] },
  IBANK: {
    title: 'International Exchange Bank',
    range: [12],
  },
  ISLAM: {
    title: 'Islamic Bank (Al-Amanah Islamic Investment Bank)',
    range: [10],
  },
  LBP: {
    title: 'Land Bank of the Philippines',
    range: [10],
  },
  LDB: { title: 'Luzon Development Bank', range: [10] },
  MAL: { title: 'Malayan Bank', range: [15] },
  MAY: { title: 'MayBank Phils', range: [11] },
  MBTC: {
    title: 'Metropolitan Bank and Trust Company',
    range: [13],
  },
  ONB: {
    title: 'One Network Bank',
    range: [11, 12],
  },
  // 'PRODS': { title: 'PRODUCERS SAVINGS BANK CORPORATION' },
  PBANK: { title: 'Pen Bank', range: [10] },
  PBCOM: {
    title: 'Philippine Bank of Communications',
    range: [12],
  },
  PBB: { title: 'Philippine Business Bank', range: [12] },
  PNB: {
    title: 'Philippine National Bank',
    range: [12],
  },
  PSB: { title: 'Philippine Savings Bank', range: [12] },
  PTB: {
    title: 'Philippine Trust Company',
    range: [10, 12],
  },
  PVB: {
    title: 'Philippine Veterans Bank',
    range: [10, 13],
  },
  PLB: { title: 'Planters Bank', range: [10] },
  POB: { title: 'Postal Bank', range: [12] },
  PRB: { title: 'Premiere Bank', range: [12] },
  RCBC: {
    title: 'Rizal Commercial Banking Corp.',
    range: [10],
  },
  RSB: { title: 'Robinson Savings Bank', range: [15] },
  SB: { title: 'Security Bank Corporation', range: [13] },
  SCB: { title: 'Standard Chartered Bank', range: [13] },
  SEA: { title: 'SEABANK', range: [10, 16] },
  STRBK: { title: 'Sterling Bank', range: [12] },
  TYB: { title: 'Tong Yang Savings Bank' },
  UCPBS: { title: 'UCPB Savings Bank', range: [10, 11] },
  UB: {
    title: 'Union Bank of the Philippines',
    range: [12],
  },
  UCPB: {
    title: 'United Coconut Planters Bank',
    range: [12],
  },
  UOB: { title: 'United Overseas Bank', range: [12] },
  USB: { title: 'University Savings Bank', range: [15] },
};

const PHILIPPINES_BANK_TITLE_CODES = PHILIPPINES_BANK_CODES.map((code) => {
  const { title } = PHILIPPINES_BANK_INFO_BY_BANK_CODES[code];
  return {
    code,
    title,
  };
});

export const BANK_CODES_BY_COUNTRY: Record<ISOCountry, Array<BankCode>> = {
  PH: PHILIPPINES_BANK_TITLE_CODES,
  US: [],
  MX: [],
  CA: [],
};
